import { createSlice } from "@reduxjs/toolkit";

const planDetailsSlice = createSlice({
  name: "planDetails",
  initialState: [],
  reducers: {
    planDetailsData(state, action) {
      state.push(action.payload);
    },
  },
});

export default planDetailsSlice.reducer;
export const { planDetailsData } = planDetailsSlice.actions;
