import { createSlice } from "@reduxjs/toolkit";

const userdetails = createSlice({
  name: "userdetails",
  initialState: [],
  reducers: {
    userInfo(state, action) {
      if(state.length<=0){
        state.push(action.payload);
      }
      
    },
    planDetails(state, action) {
      state.push(action.payload);
    },
  },
});

export default userdetails.reducer;
export const { userInfo } = userdetails.actions;
export const { planDetails } = userdetails.actions;
