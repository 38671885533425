import { configureStore } from "@reduxjs/toolkit";
import userdetails from "./userSlice.js";
import familymemberSlice from "./familymemberSlice.js";
import planDetailsSlice from "./planDetailsSlice.js";
import personalSlice from "./personalSlice.js";
import snackbarSlice from "./snackbarSlice.js";
import KycDetails from "./KycDetails.js";

export const store = configureStore({
  reducer: {
    QuoteId: userdetails,
    familyMembers: familymemberSlice,
    planDetailsData: planDetailsSlice,
    personalDetails:personalSlice,
    snackbar: snackbarSlice,
    kycData: KycDetails

  },
});
