import { createSlice } from "@reduxjs/toolkit";

const personalSlice = createSlice({
  name: "planDetails",
  initialState: [],
  reducers: {
    personalData(state, action) {
      state.push(action.payload);
    },
  },
});

export default personalSlice.reducer;
export const { personalData } = personalSlice.actions;
