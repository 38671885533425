// DBService.js
const DB_NAME = "MyDatabase";
const DB_VERSION = 1;

// Function to check if IndexedDB is supported
const isIndexedDBSupported = () => {
  return window.indexedDB;
};

// Function to open database
const openDatabase = () => {
  return new Promise((resolve, reject) => {
    if (isIndexedDBSupported()) {
      const request = indexedDB.open(DB_NAME, DB_VERSION);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        // Create object stores for each form type
        db.createObjectStore("FormDataStore1", {
          keyPath: "id",
          autoIncrement: true,
        });
        // Add more object stores as needed
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    } else {
      reject(new Error("IndexedDB is not supported."));
    }
  });
};

// Function to add or update form data
const addOrUpdateFormData = async (formData, objectStoreName) => {
  try {
    if (isIndexedDBSupported()) {
      const database = await openDatabase();
      const transaction = database.transaction([objectStoreName], "readwrite");
      const objectStore = transaction.objectStore(objectStoreName);

      // Check if formData has an 'id' property
      if (formData.id) {
        const getRequest = objectStore.get(formData.id);

        getRequest.onsuccess = (event) => {
          const existingData = event.target.result;

          if (existingData) {
            // Data already exists, update it
            const updateRequest = objectStore.put(formData);

            updateRequest.onsuccess = () => {
              console.log("Form data updated successfully.");
            };

            updateRequest.onerror = (event) => {
              console.log("Error updating form data:", event.target.error);
            };
          } else {
            // Data doesn't exist, add it
            const addRequest = objectStore.add(formData);

            addRequest.onsuccess = () => {
              console.log("Form data added successfully.");
            };

            addRequest.onerror = (event) => {
              console.log("Error adding form data:", event.target.error);
            };
          }
        };

        getRequest.onerror = (event) => {
          console.log("Error getting form data:", event.target.error);
        };
      } else {
        // If 'id' is not present in formData, reject the promise
        throw new Error("Form data must have an 'id' property.");
      }
    } else {
      // Fallback to localStorage
      const key = `${objectStoreName}-${formData.id}`; // Prepend object store name to the key
      localStorage.setItem(key, JSON.stringify(formData));
    }
  } catch (error) {
    console.log("Error:", error);
    throw error; // Ensure error is propagated
  }
};

// Function to get record by ID
const getRecordById = async (id, objectStoreName) => {
  try {
    if (isIndexedDBSupported()) {
      // If IndexedDB is supported, retrieve data from IndexedDB
      const database = await openDatabase();
      const transaction = database.transaction([objectStoreName], "readonly");
      const objectStore = transaction.objectStore(objectStoreName);
      const getRequest = objectStore.get(id);

      return new Promise((resolve, reject) => {
        getRequest.onsuccess = (event) => {
          resolve(event.target.result);
        };

        getRequest.onerror = (event) => {
          reject(event.target.error);
        };
      });
    } else {
      // If IndexedDB is not supported, retrieve data from localStorage
      const key = `${objectStoreName}-${id}`; // Prepend object store name to the key
      const storedData = localStorage.getItem(key);
      if (storedData) {
        return JSON.parse(storedData);
      } else {
        return null; // Return null if data not found
      }
    }
  } catch (error) {
    console.log("Error:", error);
    throw error; // Ensure error is propagated
  }
};

// Function to delete all data
const deleteAllData = async () => {
  if (isIndexedDBSupported()) {
    return deleteAllDataFromIndexedDB();
  } else {
    return deleteAllDataFromLocalStorage();
  }
};

// Function to delete all data from IndexedDB
const deleteAllDataFromIndexedDB = async () => {
  try {
    const database = await openDatabase();
    const objectStoreNames = database.objectStoreNames;

    return new Promise((resolve, reject) => {
      const transaction = database.transaction(
        Array.from(objectStoreNames),
        "readwrite"
      );

      transaction.oncomplete = () => {
        resolve("All data deleted from IndexedDB successfully.");
      };

      transaction.onerror = (event) => {
        reject(event.target.error);
      };

      // Delete all data from each object store
      for (let i = 0; i < objectStoreNames.length; i++) {
        const objectStoreName = objectStoreNames[i];
        transaction.objectStore(objectStoreName).clear();
      }
    });
  } catch (error) {
    throw new Error(`Error deleting all data from IndexedDB: ${error}`);
  }
};

// Function to delete all data from localStorage
const deleteAllDataFromLocalStorage = () => {
  try {
    localStorage.clear();
    return "All data deleted from localStorage successfully.";
  } catch (error) {
    throw new Error(`Error deleting all data from localStorage: ${error}`);
  }
};

export { addOrUpdateFormData, getRecordById, deleteAllData };
