import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { deleteAllData } from "./Services/data-manager";
import "./App.scss";
const Header = lazy(() => import("./Component/Header/Header"));
const Footer = lazy(() => import("./Component/Common/Footer/Footer"));
const GotoTop = lazy(() => import("./Component/Common/GotoTop/GotoTop"));
const HomeComponent = lazy(() => import("./Pages/Home/Home"));
const SuperTopup = lazy(() => import("./Pages/SuperTopup"));
const NotFound = lazy(() => import("./Pages/PageNotFound/pageNotFound"));
const ScrollToTop = lazy(() =>
  import("./Component/Common/ScrollTop/ScrollTop")
);
const PaymentThankyou = lazy(() =>
  import("./Component/SelectedPlan/PaymentThankyou")
);
const ErrorBoundary = lazy(() =>
  import("./Component/ErrorBoundary/ErrorBoundary")
);
const PlanList = lazy(() => import("./Component/CustomPlans/CustomPlansList"));
const SelectedPlan = lazy(() =>
  import("./Component/SelectedPlan/SelectedPlans")
);
const FeatureSection = lazy(() =>
  import("./Model/FeatureModel/FeatureSection")
);
const TermsAndcondition = lazy(() =>
  import("./Component/TermsAndcondtion/TermsAndcondition")
);
const PrivacyPolicy = lazy(() =>
  import("./Component/PrivacyPolicy/PrivacyPolicy")
);

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const quoteIdFromURL = urlParams.get("quoteId");

  // If 'quoteId' is not present in the URL, delete all data
  if (!quoteIdFromURL) {
    // Call a function to delete all data (replace this with your actual deletion logic)
    deleteAllData();
  }

  return (
    <div>
      <Router>
        <Suspense fallback={<div className="loading">Loading...</div>}>
          <Header />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomeComponent />} exact={true} />
            <Route
              path="/health-insurance"
              element={<SuperTopup />}
              exact={true}
            />
            {/* <Route path="/insurance" element={<Insurance />} exact={true} /> */}

            {/* Lazy-loaded routes */}
            <Route path="/plan-list" element={<PlanList />} exact={true} />
            <Route
              path="/plan-list/selected-plan"
              element={<SelectedPlan />}
              exact={true}
            />
            <Route
              path="/feature-section"
              element={<FeatureSection />}
              exact={true}
            />

            <Route
              path="/payment/thank-you"
              element={
                <ErrorBoundary>
                  <PaymentThankyou />
                </ErrorBoundary>
              }
              exact={true}
            />
            <Route
              path="/terms-conditions"
              element={<TermsAndcondition />}
              exact={true}
            ></Route>
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicy />}
              exact={true}
            ></Route>
            <Route path="*" element={<NotFound />} />
          </Routes>

          <GotoTop />
          <Footer />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
