import {createSlice} from "@reduxjs/toolkit";

const snackbarSlice=createSlice({
    name:"familyInformation",
    initialState:[] ,
    reducers:{
      snackbarAction(state,action) {
        state.push(action.payload)
      }
    }
})
export default snackbarSlice.reducer;
export const { snackbarAction}=snackbarSlice.actions;
