import {createSlice} from "@reduxjs/toolkit";

const KycDetail=createSlice({
    name:"KycDetails",
    initialState:[] ,
    reducers:{
      KycDetailData(state,action) {
        state.push(action.payload)
      }
    }
})
export default KycDetail.reducer;
export const {KycDetailData}=KycDetail.actions;
