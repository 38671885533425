import {createSlice} from "@reduxjs/toolkit";

const familymemberDetails=createSlice({
    name:"familyInformation",
    initialState:[] ,
    reducers:{
      familyInformation(state,action) {

        state.push(action.payload)
      }
    }
})
export default familymemberDetails.reducer;
export const {familyInformation}=familymemberDetails.actions;
